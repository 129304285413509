export default function imgixLoader({ src, width, quality }) {
  try {
    const url = new URL(src);

    if (url.host === 'ourahealth.imgix.net') {
      const params = url.searchParams;
      params.set('w', params.get('w') || width.toString());
      return url.href;
    }

    return src;
  } catch (error) {
    return src;
  }
}
